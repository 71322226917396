import React from "react";
import "./css/Team.css";
import users from "./TeamNames";
import about1 from "./image/about1.png";
import Teamcard from "./Teamcard";
import Navigation from "./Navigation";

// import director from "./image/pp/director.jpg";
// import shantanu from "./image/pp/shantanu.jpg";
// import Ananth_Kmar from "./image/pp/Ananth_Kmar.jpg";
// import mukesh12 from "./image/pp/mukesh12.jpeg";
// import sanka from "./image/pp/sanka.jpeg";
// import Vijay from "./image/pp/Vijay.jpeg";
// import asho from "./image/pp/asho.jpg";
import Footer from "./Footer";
// import vijay123 from "./image/pp/vijay123.jpeg";
// import mahendran123 from "./image/pp/mahendran123.jpeg";
// import Prakash from "./image/pp/Prakash.JPG";
// import Aneesh from "./image/pp/Aneesh.jpeg";
// import kiru from "./image/pp/kiru.jpeg";
// import siva from "./image/pp/siva.jpeg";
// import manager from "./image/pp/manager.jpeg";
// import kura from "./image/pp/kura.jpeg";
// import mani from "./image/pp/mani.jpeg";
// import tyagu from "./image/pp/tyagu.jpeg";
// import team from "./image/team.svg";
// import logotop from "./image/logotop.jpg";
// import maindarshil from "./image/maindarshil.png.jpg";
// import yuvraj from "./image/yuvraj.jpg";
// import dummy from "./image/dummy.png";
import nikhilT from "./image/pp/nikhil.jpg";
// import Nikhil from "./image/pp/Nikhil.jpeg";
// import linkdin from "./image/linkdin.png";
// import mail from "./image/mail.png";
const nikhil = {
  name: "Nikhil Taparia",
    designation: "Full Stack Developer, DEI",
    email: "taparianikhil13@gmail.com",
    linkedin: "https://www.linkedin.com/in/nikhil-taparia/",
    image: nikhilT,
}
const  suvan = {
  name: "Suvan Gowri Shanker",
  designation: "Full Stack Developer, DEI",
  email: "suvan.gowrishanker.204@gmail.com",
  linkedin :"https://www.linkedin.com/in/suvan-gowri-shanker-596943261/",
  image: "https://i.postimg.cc/tJtKCvQy/6x8-2.jpg",
}

export const Team = () => {
  return (
    <>
      <Navigation />

      <div>
        <div className="abouttop">
          <img className="imgbackabout" src={about1} alt="BackgroundImage" />
        </div>
        <div className="event3">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            Team
          </h3>
        </div>

        <div className="teamsir">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-10 m-2 md:m-14">
            {users.map((item) => {
              return <Teamcard props={item} />;
            })}
          </div>
        </div>
       

        
        <div className="event3">
          <h3 className="animate-character text-3xl md:text-6xl font-ubuntu mt-6">
            CREW
          </h3>
        </div>
        <div className="teamsir">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-10 m-2 md:m-14">
           <Teamcard props={nikhil} />
           <Teamcard props={suvan} />
          </div>
        </div>
        

      </div>

      <Footer />
    </>
  );
};
export default Team;
